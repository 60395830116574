<template>
  <div>
    <div id="qrcode" class="qrcode" ref="qrCodeUrl" style="display: none"></div>
    <div v-if="!showMsg" class="SendSMSMobile">
      <span v-if="!$route.query.href" style="font-size: 12px;position: fixed;right: 35px;top: 5px;color: #999;">点击右上角 <i
          class="el-icon-more"></i> 分享微信好友 <i class="el-icon-top-right"></i> </span>
      <el-descriptions :title="Title" :column="1" border labelClassName="leftKey">
        <el-descriptions-item v-for="(item, index) in formData" :key="index" :label="item.Key">
          <span v-if="item.Keys == 'FullName'">{{ FullName }}</span>
          <span v-else-if="item.Keys == 'SigningDate'">{{
            SigningDate ? SigningDate.substring(0, 10) : ""
          }}</span>
          <span v-else-if="item.Keys == 'ReceiptPhone'">{{
            ReceiptPhone
          }}</span>
          <span v-else-if="item.Keys == 'IdCard'">{{ IdCard }}</span>
          <span v-else>{{ item.value }}</span>
        </el-descriptions-item>
        <el-descriptions-item v-for="(item, index) in entity.evaluation" :key="index" :label="item">
          <el-rate v-if="item == '服务'" disabled v-model="entity['service']" />
          <el-rate v-if="item == '物流'" disabled v-model="entity['logistics']" />
          <el-rate v-if="item == '产品'" disabled v-model="entity['product']" />
        </el-descriptions-item>
        <el-descriptions-item label="图片">
          <el-image style="width: 80px; height: 80px" v-for="(item, index) in imgs" :key="index" :src="item" fit="fill"
            :preview-src-list="imgs">
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag v-if="Status == 0">待签收</el-tag>
          <el-tag v-if="Status == 1" type="success">已签收</el-tag>
          <el-tag v-if="Status == 2" type="danger">已拒收</el-tag>
          <el-tag v-if="Status == 3" type="warning">有差异</el-tag>
          <el-tag v-if="Status == 4" type="danger">已作废</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <div style="margin-top: 10px" v-for="(item, index) in tableData" :key="index">
        <el-table :data="item.datalist" header-row-class-name="tableHeader">
          <el-table-column v-for="(im, ix) in item.columns" :key="ix" :prop="im.dataIndex" :label="im.title"
            min-width="110" align="center">
            <template slot-scope="scope">
              {{ NumFormatting(item.datalist[scope.$index][im.dataIndex]) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="signAndSeal">
        <div class="Seal Box" v-if="zdshow">
          <span>制单人 &nbsp;{{ SealName }}</span>
          <img v-if="Seal" :src="Seal" alt="" />
        </div>
        <div class="Sign Box" v-if="qsshow">
          <span>签收人</span>
          <img v-if="Sign" :src="Sign" alt="" />
        </div>
      </div>
    </div>
    <div v-if="!showMsg" style="
        text-align: center;
        margin-top: 30px;
        border-top: 1px solid #ebeef5;
        padding-bottom: 40px;
        background: #fafafa;
      ">
      <div v-if="!$route.query.qrcode && Status == 0" style="padding-top: 10px">
        <img :src="qrcodeimg" alt="" />
        <p style="margin: 10px 0">使用微信扫一扫进入小程序进行签收</p>
        <a :href="href" v-if="href">
          <el-button style="width: 90%;" type="primary">去 签 收 </el-button>
        </a>
      </div>
      <div v-else style="padding-top: 10px">
        <p style="margin: 10px 0">服务完成二维码</p>
        <img :src="qrcodeimg" alt="" />
        <p style="margin: 10px 0">出示此二维码给服务方确认</p>
        <a style="margin-top: 20px; display: inline-block" :href="PDFUrl">
          <el-button type="primary" icon="el-icon-download" round>下载</el-button>
        </a>
      </div>
    </div>
    <div v-else class="imgB">
      <div class="qianshou">
        <img src="@/assets/menuLogo.png" alt="" width="200" />
        <div v-if="!$route.query.qrcode" class="imgBox">
          <img :src="qrcodeimg" alt="" />
        </div>
        <!-- <div>授权码</div>
        <h1 style="margin: 0">{{ smsCode }}</h1> -->
        <p v-if="!$route.query.qrcode">
          使用微信扫一扫<br />进入小程序进行签收
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const NumFormatting = function (data) {
  if (isNaN(Number(data))) {
    return data;
  } else {
    return Number(data).toFixed(2);
  }
};
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      entity: {}, // JSON
      formData: [], // 整体表单
      tableData: [], // 表格渲染
      imgs: [], // 附件
      billid: "", // 表单ID
      Sign: "", // 签名
      Seal: "", // 签章
      SealName: "",
      Title: "", // 标题
      sendMessage: "", // 转发内容
      PDFUrl: "",
      smsCode: "",
      ReceiptTitle: "",
      showMsg: false,
      zdshow: false,
      qsshow: false,
      Status: 0,
      qrcodeimg: "",
      NumFormatting,
      href: ""
    };
  },
  async mounted() {
    this.billid = this.$route.query.id;
    await this.getFormData();
    await this.getShareInfo();
  },
  methods: {
    creatQrCode(text) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });

      let qrcodeEle = document.getElementById("qrcode");
      let cvs = qrcodeEle.querySelector("canvas");
      this.qrcodeimg = cvs.toDataURL("image/png");
    },
    async getFormData() {
      let resJson = await this.$http.post("/BO/BillList/GetBillDetail", {
        id: this.billid,
      });
      if (resJson.Success && resJson.Data) {
        let qrcode = resJson.Data.QrCode.replace('&Type=0', '&Type=1')
        if (resJson.Data.Status == 0) {
          this.creatQrCode(qrcode);
          // 获取微信短链
          if (this.$route.query.href) {
            this.$http
              .post("/BO/BillList/GetBillLink", {
                id: qrcode,
              })
              .then((result) => {
                if (result.Success) {
                  this.href = result.Data;
                }
              });
          }
        } else
          this.creatQrCode(
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxde354ac424a66adc&redirect_uri=http%3A%2F%2Fpsq.yoojet.com%2F%23%2FSendSMSPublic%3Fid%3D" +
            resJson.Data.Id +
            "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
          );

        this.smsCode = resJson.Data.SmsCode;
        this.Status = resJson.Data.Status;
        this.Sign = resJson.Data.Sign;
        this.Seal = resJson.Data.Seal;
        this.FullName = resJson.Data.FullName; // 姓名
        this.SigningDate = resJson.Data.SigningDate; // 签收日期
        this.ReceiptPhone = resJson.Data.ReceiptPhone; // 收货人手机号
        this.IdCard = resJson.Data.IdCard; // 身份证号
        this.ReceiptTitle = resJson.Data.ReceiptTitle; // 微信分享标题
        this.PDFUrl = resJson.Data.PDFUrl; // PDF下载
        if (this.$route.query.type == 1) {
          this.showMsg = true;
          this.sendMessage = resJson.Data.SendMessage.split("_")[1];
        } else {
          this.showMsg = false;
          this.sendMessage = resJson.Data.SendMessage.split("_")[0];
        }
        this.imgs = resJson.Data.Imgs ? resJson.Data.Imgs.split(",") : [];
        // 确认内容解析
        if (
          resJson.Data.Content &&
          JSON.parse(resJson.Data.Content).printlist.length > 0
        ) {
          this.entity = JSON.parse(resJson.Data.Content);
          let arr = JSON.parse(resJson.Data.Content).printlist;
          let fit = ["1", "3", "4", "5", "7", "8", "9", "10"];
          arr.forEach((item) => {
            // 取出非表格/标题/制单人/签收人并排序 横线竖线方框
            // console.log(item.Key, item.type, item.Keys, item.ispringt);
            if (item.Keys == "Salesperson") {
              this.SealName = item.value;
            }
            if (item.Key == "制单人") {
              this.zdshow = true;
            } else if (item.Key == "签收人") {
              this.qsshow = true;
            } else if (fit.findIndex((im) => im == item.type) == -1) {
              this.formData.push(item);
            } else if (item.type == "1" && item.Keys == "Title") {
              this.Title = item.value;
            } else if (item.type == "4") {
              this.tableData.push(item);
            }
          });
          this.formData = this.formData.sort((a, b) => a.index - b.index);
        }
      } else {
        this.$message.error("数据获取失败");
      }
      // 重量 数量合计并赋值
      let num = 0;
      let weight = 0;
      let realNums = 0;
      let price = 0;
      this.tableData.forEach((item) => {
        item.datalist.forEach((im) => {
          console.log(im);
          num += Number(im.Quantity);
          weight += Number(im.Weight1);
          realNums += Number(im.nums);
          price += Number(im.SumiInvRCost);
        });
      });
      let numIndx = this.formData.findIndex((item) => item.Keys == "countnums");
      let weightIndx = this.formData.findIndex((item) => item.Keys == "wights");
      let realIndx = this.formData.findIndex((item) => item.Keys == "signnums");
      let priceIndx = this.formData.findIndex(
        (item) => item.Keys == "SumiInvRCost"
      );
      if (numIndx != -1)
        this.formData[numIndx].value = isNaN(num) ? 0 : num.toFixed(2);
      if (weightIndx != -1)
        this.formData[weightIndx].value = isNaN(weight) ? 0 : weight.toFixed(2);
      if (priceIndx != -1)
        this.formData[priceIndx].value = isNaN(price) ? 0 : price.toFixed(2);
      if (realIndx != -1)
        this.formData[realIndx].value = isNaN(realNums)
          ? 0
          : realNums.toFixed(2);
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://psq.yoojet.com/"
      );
      let arr = ticketData.Data.split(",");
      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: this.ReceiptTitle || "电子货单",
          desc: this.sendMessage,
          link: location.href + '&href=1',
          imgUrl: "https://psq.yoojet.com/logo200.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
    // 身份信息获取
  },
};
</script>

<style lang="less" scoped>
.imgB {
  background-image: url("../../assets/PublicAccountBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.qianshou {
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  padding-top: 20vh;

  .imgBox {
    padding: 10px;
    border: 1px solid #ccc;
    background: #fff;
    width: 180px;
    margin: 0 auto;
  }

  p {
    margin-top: 20px;
  }
}

/deep/.leftKey {
  width: 100px;
}

/deep/.tableHeader {
  th {
    background: #fafafa;
  }
}

.SendSMSMobile {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  .signAndSeal {
    margin-top: 10px;
    display: flex;

    .Box {
      flex: 1;
      position: relative;

      img {
        position: absolute;
        width: 100px;
        left: 50px;
        top: 20px;
      }
    }

    .Sign {
      img {
        transform: rotate(-90deg);
        width: 60px;
        left: 75px;
        top: -30px;
      }
    }
  }
}
</style>
  